// Para cada input com o type="file"
$('.form-group-input-file input[type="file"]').each(function() {
	var $this        = $(this); // <input type="file">
	var validation   = $this.data('valida-tipos'); // valor do atributo data-valida-tipos
	var parent       = $this.parent('.form-group-input-file');
	var output       = parent.find('.output');
	var label        = parent.find('label.form-control');
	var placeholder  = output.text();
	var $btnSend     = $this.parents('form').find('button');
	
	if(validation) {
		validation = validation.split(','); // ['pdf', 'doc', ...]
		validation = validation.map(item => item.trim())
	}

	$this.on('change', function(){
		var file = this.files[0];
		var isValid = false;

		validation.forEach(type => {
			if(file.name.indexOf(type) > -1) {
				isValid = true;
			}
		});


		if(!isValid) {
			parent.addClass('has-validation');
			$this.after('<div class="invalid-tooltip d-inline-block" style="position:relative"><i class="fas fa-exclamation-triangle"></i> Formato de arqivo inválido!</div>')
			label.css({
				'border': '1px solid var(--danger)'
			})
			output.text(placeholder);
			$this.val('')

			$btnSend.attr('disabled', 'true')
		}else {
			label.removeAttr('style');
			parent.removeClass('has-validation');
			parent.find('.invalid-tooltip').remove();
			output.text(file.name)
			$btnSend.attr('disabled', false)
		}
	});
});