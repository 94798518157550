

var esq = $('[data-direcao="prev"]');

var dir = $('[data-direcao="next"]');

esq.each(function(){

	$(this).click(function(){
		event.preventDefault();
		var attr = $(this).attr('href');
		$(attr).slick('prev');
	});

});

dir.each(function(){

	$(this).click(function(){
		event.preventDefault();
		var attr = $(this).attr('href');
		$(attr).slick('next');
	});

});