var alvoNewsletter = $('.ativar-newsletter-form-topo');

alvoNewsletter.each(function(){

	$(this).click(function(){

		var alvo = $('#newsletter-form');
		alvo.toggleClass('d-none');

	});

});